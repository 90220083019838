<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
/**
 * Todo component
 */
export default {
  components: { VuePerfectScrollbar },
  props:{
    todoData: {
      default: function () { return [] },
      type:Array,
    },
  },
  data() {
    return {
      todolist: {
        todo: '',
      },
      todoSubmitted: false,
      completedToDos: Array,
      uncompletedToDos: Array
    }
  },
  computed: {
    /**
     * return completed todo
     */
    completedTodo() {
      return [...this.todoData.filter(x => x.isCompleted===true)]
    },
    uncompletedTodo() {
      return [...this.todoData.filter(x => x.isCompleted===false)]
    }
  },
  created: function(){
    this.uncompletedToDos = [...this.todoData.filter(x => x.isCompleted===false)];

    this.$store.subscribe((mutation) => {
      if(mutation.type === 'auth/UPDATE_CURRENT_USER_TO_DOS'){
        this.todoData = this.$store.state.auth.currentUser.userToDo;
      }
    });
  },
  methods: {
    /**
     * Todo form submit
     */
    saveTodo() {
      this.todoSubmitted = true;

      if(!this.todolist.todo) return;
      const todo = this.todolist.todo;

      return this.$store.dispatch('auth/addToDo', {description: todo}).then(result =>{
        this.todoSubmitted = false;
        this.todolist = {};
      }).catch( error => {
        this.updateError = error;
      })
    },

    /**
     * Check box value change
     */
    updateCheck(index) {
      return this.$store.dispatch('auth/switchToDoStatus', { id: index}).then(result => {

      }).catch(error => {
        this.updateError = error;
      });

    },

    deleteToDo(todoId){

      return this.$store.dispatch('auth/deleteUserToDo', {id: todoId});
    },
  },
}
</script>
<style>
  .text-checked{
    text-decoration: line-through;
  }
  .todoapp label{
    max-width:80%;
  }

</style>

<template>
  <div class="card" style="min-height: 450px">
    <div class="card-body">
      <b-card-title>Λίστα to-do</b-card-title>
      <b-tabs justified content-class="mt-1" nav-class="nav-tabs nav-bordered">
        <b-tab title='Ενεργά' active>
          <div class="todoapp">
            <VuePerfectScrollbar v-if="todoData.length > 0">
              <div>
                <ul id="todo-list" class="list-group list-group-flush todo-list" style="min-height:215px;max-height: 215px">

                  <li v-for="(todo) in uncompletedTodo" :key="todo.id" class="list-group-item border-0 pl-1 checkbox checkbox-primary">
                    <input :id="`${todo.id}`" :checked="`${todo.isCompleted ? true : ''}`" type="checkbox" @change="updateCheck(todo.id)"/>
                    <label :for="`${todo.id}`"><span :class="`${todo.isCompleted ? 'text-checked' : ''}`"> {{todo.description}} <br><small class="text-muted">{{formatDateWithoutHour(todo.createdAt)}}</small> </span></label>
                    <b-button variant="light" class="float-right btn-xs" @click="deleteToDo(todo.id)"><i class="fe-trash"></i></b-button>
                  </li>
                </ul>
              </div>
            </VuePerfectScrollbar>


              <div class="row mt-2">

                <div class="col-10 todo-input">

                  <input v-model="todolist.todo" type="text" class="form-control " placeholder="Προσθήκη νέου to-do" name="todo" :class="{ 'is-invalid': todoSubmitted && !todolist.todo }"/>
                  <div v-if="todoSubmitted && !todolist.todo" class="invalid-feedback">Το πεδίο είναι απαραίτητο.</div>
                </div>
                <div class="col-2 add-todo">
                  <button type="submit" class="btn btn-block btn-primary add-todo" @click="saveTodo"><i class="fe-plus"></i></button>
                </div>
              </div>
              <!-- end row -->
            <!-- end form -->
          </div>
        </b-tab>
        <b-tab title="Ολοκληρωμένα">
          <div class="todoapp">
            <VuePerfectScrollbar v-if="todoData.length > 0">
              <div>
                <ul id="completed-todo-list" class="list-group list-group-flush todo-list" style="max-height: 320px">

                  <li v-for="(todo) in completedTodo" :key="todo.id" class="list-group-item border-0 pl-1 checkbox checkbox-primary">
                    <input :id="`${todo.id}`" :checked="`${todo.isCompleted ? true : ''}`" type="checkbox" @change="updateCheck(todo.id)"/>
                    <label :for="`${todo.id}`"><span :class="`${todo.isCompleted ? 'text-checked' : ''}`"> {{todo.description}} </span></label>
                    <b-button variant="light" class="float-right btn-xs" @click="deleteToDo(todo.id)"><i class="fe-trash"></i></b-button>
                  </li>
                </ul>
              </div>
            </VuePerfectScrollbar>
            <!-- end form -->
          </div>
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>
