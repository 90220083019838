<script>
    import ProfileSidebar from "./ProfileSidebar";
    import goal from "@components/todo";

    /**
     * Profile component
     */
    export default {
        page: {
            title: 'Λογαριασμός'
        },
        components: {ProfileSidebar, Todo: goal},
        data() {
            return {
                user: this.$store ? this.$store.state.auth.currentUser : {} || {},
            }
        },
        created: function(){
            this.$store.subscribe((mutation, state) => {
                if(mutation.type === 'auth/SET_CURRENT_USER'){
                    this.user = this.$store.state.auth.currentUser;
                }
            });
        },
    }
</script>
<template>
    <div id="to-do-layout">
        <div class="row">
            <ProfileSidebar></ProfileSidebar>
            <div class="col-lg-9 col-xl-9">
                <div class="card">
                    <Todo :todo-data="user.userToDo"></Todo>
                </div>
                <!-- end card-box-->
            </div>
            <!-- end col -->
        </div>
        <!-- end row-->
    </div>
</template>
